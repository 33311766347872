import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Grid, Typography, FormHelperText } from "@mui/material";

export default function TextFieldsInput({
  width,
  name,
  value,
  handleValue,
  helperText,
  id,
  size,
  multiline,
  error,
  endLabel,
  field,
  placeholder,
  type,
  disabled
}: any) {
  // const [values, setValues] = useState(value);
  const handleChange = (event: any) => {
    let value = event.target.value;

    // Prevent empty spaces
    value = value.trimStart(); // Removes leading spaces
    if (value === "" && event.target.value !== "") {
      // If the user only typed spaces, return without updating
      return;
    }

    handleValue(name, value);
  };
  const renderEndAdornment = () => {
    if (endLabel) {
      return (
        <InputAdornment style={{ fontSize: "13px" }} position="end">
          {endLabel}
        </InputAdornment>
      );
    }
    return null;
  };
  // useEffect(() => {
  //   setValues(value);
  // }, [value]);
  return (
    <>
      {multiline ? (
        <>
          <Grid xs={12} sm={12} md={12}>

            <TextField
              id="outlined-multiline-static"
              placeholder="Enter Product Description"
              multiline
              rows={4}
              name={name}
              fullWidth
              variant="filled"
              InputProps={{ disableUnderline: true }}
              onChange={(e) => handleChange(e)}
              value={value}
              error={error}
              helperText={helperText}
              disabled={disabled}
              sx={{
                "& .MuiFilledInput-input": {
                  fontSize: "13px",
                  marginTop: '-19px',
                  padding: '2px'
                },
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid alignItems={'center'} container>
          {
            //22-11-23
            (name !== "skuRange" && name !== "overFillCapacity" && name != "weight") && (
              <Grid xs={12} sm={12} md={4}>
                <Typography variant="body1">{field}</Typography>
              </Grid>

            )
          }
          {/* 22-11-23 */}
          <Grid sx={{ padding: { xs: '10px 0px 0px 0px' } }} xs={12} sm={(name == "skuRange" || name == "overFillCapacity" || name == "weight") ? 12 : name == "productCode" ? 12 : 12} md={(name == "skuRange" || name == "overFillCapacity" || name == "weight") ? 11.5 : name == "productCode" ? 12 : 8}>
            <TextField
              sx={{
                width: `${width}`,
                "& .MuiFilledInput-input": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "8px",
                  fontSize: "13px",
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  '-moz-appearance': 'numbers',
                },
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              id={`${id}` || "outlined-select-currency"}
              size={size}
              placeholder={placeholder}
              name={name}
              type={type}
              value={value}
              InputProps={{
                disableUnderline: true,
                endAdornment: renderEndAdornment(),
              }}
              onChange={(e) => handleChange(e)}
              variant="filled"
              disabled={disabled}
            ></TextField>
            {error && <FormHelperText error>{helperText}</FormHelperText>}
          </Grid>
        </Grid>

      )}

    </>

  );
}
