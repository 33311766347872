import { Avatar, Divider, Grid, Box, Drawer } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useRef, useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import FilterOptionsComponent from "../../components/index-components/filter-options.component";
import ProductListComponent from "../../components/index-components/product-list.component";
import SearchComponent from "../../components/index-components/search-component";
import { stringValidation, INITIAL_STATES } from "../../constants/Initial_states";
import { validationToOperators } from "../../hooks/validations";
import { fetchSuggestion, fetchProducts } from "../../services/product.service";
import BottomCompareComponent from "../../components/index-components/bottom-compare.component";
import Context from "../../hooks/context";
import _ from "lodash";
import { ToastContainer, toast } from 'react-toastify';
import { SkeleTonLoader } from "../../components/commons/skeleton-loader";

export default function Home() {
  
  const contextData: any = useContext(Context);
  const [data, setData] = useState([]);
  const [isNextPage, setIsNextPage] = useState(true);
  const [isSkeleTon, setIsSkeleTon] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [operators, setOperators] = useState(INITIAL_STATES);
  const [filterOpen, setFilterOpen] = useState(true);
  const operatorsRef = useRef(operators);
  const [state, setState] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const handleCloseDrawyer = () => {
    setState(false);
  };
  const fetchProductList = async (filters: any) => {
    setIsSkeleTon(true);
    const finalPayload = validationToOperators(filters, 'filters');
    operatorsRef.current.pageNumber = 0;
    await fetchProducts({ data: finalPayload, type: finalPayload.type }).then((res: any) => {

      setData(res?.data?.data);
      setIsNextPage(res.data.hasNext);
      setTimeout(() => {
        setIsSkeleTon(false);
      }, 400);
    }).catch((error) => {
      toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSkeleTon(false);
    });
  };

  const handleSearch = (value: any) => {
    if (value !== '') {
      setOperators((prevList: any) => ({
        ...prevList,
        search: value,
      }));
      operatorsRef.current.search = value;
      fetchProductList(operatorsRef.current);
    }
  };

  const fetchSuggestions = async (value: any) => {
    if (!stringValidation(value)) {
      await fetchSuggestion({ data: value, type: operators.type }).then((response: any) => {
        setSuggestions(response?.data);
      }).catch((error) => {
        toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };
  const fetchNextData = async () => {
    if (isNextPage) {
      operatorsRef.current.pageNumber = operatorsRef.current.pageNumber + 1;
      const finalPayload = validationToOperators(operatorsRef.current, 'infinite');
      await fetchProducts({ data: finalPayload, type: operators.type }).then((res: any) => {
        setIsNextPage(res.data.hasNext);
        const value: any = _.concat(data, res.data.data);
        setData(value);
      }).catch((error) => {
        toast.error(error?.length !== 0 && error !== undefined ? error : `Internal Server Error`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };


  useEffect(() => {
    fetchProductList(operators);
    contextData?.handleCompareList(operators?.type);
  }, []);

  return (
    <Box>
      <Grid container pr={2} spacing={0} position={"fixed"}>
        {filterOpen &&
          <Grid sx={{ display: { md: 'block', xs: 'none', sm: 'none' } }} item xs={12} md={2}>
            <FilterOptionsComponent operatorsRef={operatorsRef} contextData={contextData} fetchProductList={fetchProductList} operators={operators} setOperators={setOperators} setFilterOpen={setFilterOpen} />
          </Grid>}
        <Grid sx={{ display: { md: 'block', xs: 'none', sm: 'none' } }} item xs={12} md={0.2}>
          {filterOpen ? <>  <Divider sx={{ backgroundColor: '#3161BE', position: 'fixed', width: '3px', marginLeft: '18px', marginTop: '220px' }} orientation={'vertical'} />
            <Avatar onClick={() => setFilterOpen(false)} sx={{
              position: "fixed",
              top: "250px",
              padding: '0px',
              cursor: 'pointer', width: "40px", height: "40px", color: 'white', backgroundColor: '#0062be'
            }}>
              <img width={20} height={20} alt="accordian icon" src="./assets/accordianicon.svg" />
            </Avatar>

          </> : <Box sx={{ left: 0 }}> <Divider sx={{ backgroundColor: '#3161BE', position: "fixed", width: '3px', marginLeft: '0px', marginTop: '220px' }} orientation={'vertical'} />
            <Avatar onClick={() => setFilterOpen(true)} sx={{
              position: "fixed",
              top: "250px",

              marginLeft: '-15px',
              cursor: 'pointer', width: "40px", height: "40px", color: 'white', backgroundColor: '#0062be'
            }}>
              <ArrowForwardIosIcon sx={{ fontSize: '16px' }} />
            </Avatar>
          </Box>
          }
        </Grid>

        <Grid item xs={12} md={filterOpen ? 9.8 : 11.8} >
          <SearchComponent toggleDrawer={toggleDrawer} filterOpen={filterOpen} type={operators.type} setOperators={setOperators} operators={operators} suggestions={suggestions} handleSearch={handleSearch} fetchSuggestions={fetchSuggestions} > </SearchComponent>
          {isSkeleTon ? <SkeleTonLoader isProduct /> :
            <ProductListComponent type={operators.type} isNextPage={isNextPage} fetchNextData={fetchNextData} products={data} />
          }
        </Grid>
      </Grid>
      {contextData?.compareList?.length >= 1 && <BottomCompareComponent type={operators.type} />}

      <Drawer
        anchor={'left'}
        open={state}
        sx={{
          '& .MuiDrawer-paper': {
            display: { lg: 'none' },
            width: { md: '50%', sm: '70%', xs: '80%' }
          }
        }}
        onClose={toggleDrawer(false)}
      >

        <FilterOptionsComponent operatorsRef={operatorsRef} handleCloseDrawyer={handleCloseDrawyer} contextData={contextData} fetchProductList={fetchProductList} operators={operators} setOperators={setOperators} setFilterOpen={setFilterOpen} />

      </Drawer>
      <ToastContainer autoClose={5000} hideProgressBar={false} newestOnTop={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
    </Box>
  );
}
