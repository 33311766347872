import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { uploadUpdateBackStyles } from "../../styles/upload-component-style";
import { memo, useEffect, useState } from "react";
import { Button } from 'antd';
import { Link } from "react-router-dom";
import FormInputComponent from '../upload-component/form-upload';
import ProductDetailsComponent from "../product-detail-component/product-details";


const UploadUpdateBack = ({ page, handleClose, id, selectedCategory, setSelectedCategory }: any) => {
  // const router = useRouter();

  // const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <>
      <Box sx={uploadUpdateBackStyles}>
        <Grid alignItems="center" m={1} mb={2} md={12} container>

          <Grid xs={2} sm={4} md={5}>
            <Box className="upload-back-button" sx={{ display: 'flex', alignItems: 'center', width: "100px" }} onClick={() => handleClose(id)}>
              <img alt="back icon" className="icon" width={30} height={30} src="/assets/icon/back_arrow.svg" />
              <Typography variant="h6" onClick={() => handleClose(id)} className="back_text">
                Back
              </Typography>
            </Box>
          </Grid>


          <Grid xs={10} sm={8} md={7} display="flex" justifyContent={{ xs: 'flex-start', md: 'space-between' }} sx={{ textAlign: 'center' }}>

            {page && page === 'upload' ? (
              <Typography variant="body1">Upload New Product</Typography>
            ) : (
              <Typography variant="body1">Update Product</Typography>
            )}

            <Box sx={{ marginBlockStart: '-1.7vh' }}>
              <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                <InputLabel id="category-select-label">Select Category</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="category-select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Select Category"
                  MenuProps={{
                    sx: {
                      zIndex: 1500, // Add a custom zIndex value
                    },
                    PaperProps: {
                      sx: {
                        zIndex: 1500, // Ensures the menu items are on top
                      },
                    },
                  }}
                >
                  <MenuItem value={'rpt'}>RPT</MenuItem>
                  <MenuItem value={'gmt'}>GMT</MenuItem>
                  <MenuItem value={'flexy'}>FLEXY</MenuItem>
                  <MenuItem value={'impression'}>IMPRESSION</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Link to="/addVendor">
              <Button type="primary" style={{ marginRight: '22px' }}>
                Add Vendor
              </Button>
            </Link>
          </Grid>
        </Grid>
        {/* <FormInputComponent selectedCategory={selectedCategory} /> */}
        {/* <ProductDetailsComponent selectedCategory={selectedCategory} />/ */}
      </Box>


    </>
  );
};
export default memo(UploadUpdateBack);