import React, { useState, useEffect } from "react";

import _ from "lodash";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Typography, FormHelperText } from "@mui/material";

const AutoCompleteComponent = ({
  options, width, name, value, handleValue, id, size, placeholder, error, helperText, multiple, field
}: any) => {
  const [inputValue, setInputValue] = useState(value);
  const [multiValue, setMultiValue] = useState(value);

  const handleChange = (newValue: any) => {
    if (multiple == "true") {
      setMultiValue([...newValue]);
      const selectedValues: any = [];
      _.map(newValue, function (o) {
        return selectedValues.push(o.label);
      });
      handleValue(name, selectedValues);
    } else {
      setInputValue(newValue);
      const value = newValue.label;
      handleValue(name, value);
    }
  };

  useEffect(() => {
    const arr: any = [];
    if (multiple == "true") {
      value?.forEach(async (item: any) => {
        const data = options && options?.filter((ite: any) => ite.label === item);
        await arr.push(data[0]);
      });
      setMultiValue(arr);
    } else {
      const data = options && options?.filter((ite: any) => ite.label === value);
      setInputValue(data && data[0]);
    }
  }, [value, options]);
  const capitalizeFirstLetter = (str: any) => {

    return name === 'productType' ? str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase() : str;

  };
  return (
    <Grid alignItems={'center'} container>
      {
        //22-11-23
        (name !== "skuUnit" && name !== "ofcUnit" && name != "weightUnit") && (
          <Grid xs={12} sm={12} md={4}>
            <Typography variant="body1">{field}</Typography>
          </Grid>

        )
      }
      {/* 22-11-23 */}
      <Grid sx={{ padding: { xs: '10px 0px 0px 0px' } }} xs={(name == "skuUnit" || name == "ofcUnit" || name == "weightUnit") ? 12 : 12} sm={(name == "skuUnit" || name == "ofcUnit" || name == "weightUnit") ? 12 : 12} md={(name == "skuUnit" || name == "ofcUnit" || name == "weightUnit") ? 12 : 8}>
        <Autocomplete
          id={`${id}` || "combo-box-demo"}
          options={options || []}
          disableClearable
          multiple={multiple}
          popupIcon={<KeyboardArrowDownIcon sx={{ color: "#0062be" }} />}
          value={multiple == "true" ? multiValue : inputValue || []}
          onChange={(event: any, newValue: any) => {
            handleChange(newValue);
          }}
          getOptionLabel={(option: any) =>
            option == ""
              ? ""
              : capitalizeFirstLetter(option?.label)
          }
          size={size}
          sx={{
            width: `${width}`,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
              // Remove the border
            },
            zIndex:7
          }}
          renderInput={(params: any) => (
            <>

              <TextField
                {...params}
                variant="filled"
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  classes: {
                    root: "MuiFilledInput-root",
                    input: "MuiFilledInput-input",
                  },
                  style: {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "8px",
                    borderRadius: "8px",
                    fontSize: "13px",
                  },
                }}
              />
              {error && (
                <FormHelperText error={error}>
                  {helperText}
                </FormHelperText>
              )}
            </>

          )}
        />

      </Grid>

    </Grid>

  );
};

export default AutoCompleteComponent;
